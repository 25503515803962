<template>
    <vx-card title="Work Order">
        <div class="vx-input-group flex">
            <vs-tabs v-model="tabs">
                <vs-tab label="Open">
                    <TabOpen :tab="this.tabs" />
                </vs-tab>
                <vs-tab label="Approved">
                    <TabApproved :tab="this.tabs" />
                </vs-tab>
                <vs-tab label="Rejected">
                    <TabRejected :tab="this.tabs" />
                </vs-tab>
                <vs-tab label="Inquiry">
                    <TabInquiry :tab="this.tabs" />
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabApproved from "./components/tabs/tab-approved.vue";
import TabOpen from "./components/tabs/tab-open.vue";
import TabRejected from "./components/tabs/tab-rejected.vue";
import TabInquiry from "./components/tabs/tab-inquiry.vue";


export default {
    components: {
        TabOpen,
        TabApproved,
        TabRejected,
        TabInquiry
    },
    data() {
        return {
            tabs: 0,
        };
    },
    methods: {
    },
    mounted() { },
    computed: {
    },
    watch: {
    },
};
</script>
